<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VQrCard from "~/components/VQrCard.vue";
import { useUserStore } from "~/store/user";
import useQrCodeError from "~/composables/useQrCodeError";

const props = defineProps<{
  analytic?: {
    show?: string;
    close?: string;
  };
}>();

const userStore = useUserStore();
const { isActive, isOpen, close, name: modalName } = useCardModal();
const { error, reloadAfterError } = useQrCodeError();
const tryAgain = async () => {
  await userStore.getQR();
};

const { send } = AnalyticManager;
const onClose = () => {
  close();
  if (props.analytic?.close) {
    send(props.analytic?.close);
  }
};

watch(isOpen, (next) => {
  if (next) {
    if (props.analytic?.show) {
      send(props.analytic?.show);
    }
  }
});
</script>

<template>
  <VModal
    :id="modalName"
    :active="isActive"
    :open="isOpen"
    type="bottom-sheet"
    wrapper-class="card-modal"
    :closable="false"
  >
    <VQrCard :modal="true" />
    <template v-if="error" #footer>
      <div class="card-modal__buttons">
        <VButton full-width :loading="reloadAfterError" @click="tryAgain">
          Попробовать ещё раз
        </VButton>
        <VButton full-width theme="secondary" @click="onClose">
          Назад
        </VButton>
      </div>
    </template>
    <template v-else #footer>
      <VButton full-width @click="onClose">
        Закрыть
      </VButton>
    </template>
  </VModal>
</template>

<style lang="postcss">
.card-modal {
  &__buttons {
    button {
      &:first-child {
        margin-bottom: var(--pl-unit-x2);
      }
    }
  }

  & .pl-modal-footer {
    margin-top: var(--pl-unit-x2);
    border-top: none;
  }
}
</style>
